/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.logoTop{width: 200px;}
.example-spacer {
    flex: 1 1 auto;
  }
.colorBlack{
    color: #7D7D7D;
    font-size: 18px;
    margin-right: 20px;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper{
  padding-bottom: 1.6em;
}
thead{
  background-color: #3f51b5 !important;
}
th{
  color: white !important;
  font-size: 15px !important;
}
.mat-sort-header-arrow{
  color: white !important;
}
#searchPage .mat-form-field-appearance-legacy .mat-form-field-underline{
  background-color: white !important;
}
#searchPage .mat-form-field.mat-focused .mat-form-field-ripple{
  background-color: white !important;
}